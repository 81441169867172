import type { AppProps } from 'next/app';
import React, { FC, useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import '@styles/globals.scss';

import { pageview } from '@utils/gtm';
import ModalEmbedCode from '@components/ModalEmbedCode';

import { LoadingProvider } from '@utils/loading';
import withRedux from '@utils/withRedux';
import PsModal from '@components/PsModal';
import NoVerifiedWebsiteModal from '@components/NoVerifiedWebsiteModal';
import PublishModal from '@components/PublishModal';
import PublishErrorModal from '@components/PublishErrorModal';
import PublishSuccessModal from '@components/PublishSuccessModal';
import config from '@utils/config';
import UnlockFeaturesModal from '@components/UnlockFeaturesModal';
import SendCodeToDeveloperModal from '@components/SendCodeToDeveloperModal';

import {
  resetSelectedTemplate,
  resetTemplatesPageAndDeviceType,
} from '@connectors/templates/actions';
import apolloClient from '../graphql/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { PostHogProvider } from '@lib/posthog-provider';
import { NextComponentType, NextPageContext } from 'next';
import { NextPageWithAuth } from 'global';
import store from '@utils/store';
import BootstrapProvider from './bootstrap';
import TemplateModal from '@components/TemplateModal/TemplateModal';
import ChangeLayoutModal from '@components/ChangeLayoutModal/ChangeLayoutModal';
import AddDomainModal from '@features/account/websites/add-domain-modal';
import AddWebsiteModal from '@features/builder/settings/websites/add-website-modal';
import ShopifyAppExtensionModal from '@components/ShopifyAppExtensionModal';
import ShopifyConnectedStoreModal from '@components/ShopifyConnectedStoreModal';
import CampaignStatusWarningModal from '@components/CampaignStatusWarningModal';
import i18n from '../../i18n';
import Drawer from '@elements/Drawer';
import { FormSharingHowItsWork } from '@components/PublishSidebar/FormSharingAccordion/FormSharingAccordion';
import PublishAllVariantsModal from '@components/PublishAllVariantsModal';

type NextComponentWithAuth = NextComponentType<NextPageContext, any, {}> &
  Partial<NextPageWithAuth>;

type ExtendedAppProps<P = {}> = AppProps<P> & {
  Component: NextComponentWithAuth;
};
const App: FC<ExtendedAppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const isPublicRoute = Component.isPublicRoute;
    const token = store.get('user');
    if (!isPublicRoute && !token) {
      if (router.asPath !== '/') {
        const validPathname = window?.location?.pathname;
        router.push(`/login?callbackUrl=${validPathname}`);
      } else {
        router.push('/login');
      }
    }
  }, [Component]);

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  /*   useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang && i18n.language !== savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, []); */

  return (
    <>
      {config.isProd && (
        <>
          <Script
            id="customerio-1"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                      var _cio = _cio || [];
                      (function() {
                        var a,b,c;a=function(f){return function(){_cio.push([f].
                        concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                        "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
                        var t = document.createElement('script'),
                            s = document.getElementsByTagName('script')[0];
                        t.async = true;
                        t.id    = 'cio-tracker';
                        t.setAttribute('data-site-id', '07cade04adc40edd7ebc');
                        t.src = 'https://assets.customer.io/assets/track.js';
                        s.parentNode.insertBefore(t, s);
                      })();
                      `,
            }}
          />
        </>
      )}
      <ApolloProvider client={apolloClient}>
        <PostHogProvider>
          <BootstrapProvider />
          <LoadingProvider>
            <I18nextProvider i18n={i18n}>
              <Component {...pageProps} />
              <ModalEmbedCode />
              <TemplateModal
                id="TemplateDetailsModal"
                radius="30px"
                size="fullWidth"
                onClose={() => {
                  dispatch(resetTemplatesPageAndDeviceType());
                  dispatch(resetSelectedTemplate());
                }}
              />
              <div className="relative z-[1000]">
                <PsModal id="ChangeLayoutModal" size="md" type="long">
                  <ChangeLayoutModal />
                </PsModal>
              </div>
              <PsModal id="NoVerifiedWebsiteModal" radius="32px" type="long">
                <NoVerifiedWebsiteModal />
              </PsModal>
              <PsModal id="addWebsite">
                <AddWebsiteModal />
              </PsModal>
              <PsModal id="addNewWebsite">
                <AddDomainModal />
              </PsModal>
              <PsModal
                id="PublishModal"
                size={600}
                radius="32px"
                closeable={false}
              >
                <PublishModal />
              </PsModal>
              <PsModal id="PublishSuccessModal" size={600} radius="32px">
                <PublishSuccessModal />
              </PsModal>
              <PsModal id="PublishAllVariantsModal" size={400} radius="22px">
                <PublishAllVariantsModal />
              </PsModal>
              <PsModal id="PublishErrorModal" size="3xl">
                <PublishErrorModal />
              </PsModal>
              <PsModal id="UnlockFeaturesModal" size={400} radius="32px">
                <UnlockFeaturesModal />
              </PsModal>
              <PsModal id="SendCodeToDeveloperModal">
                <SendCodeToDeveloperModal />
              </PsModal>
              <PsModal id="shopifyAppExtensionModal">
                <ShopifyAppExtensionModal />
              </PsModal>
              <PsModal id="ShopifyConnectedStoreModal" size={450} radius="22px">
                <ShopifyConnectedStoreModal />
              </PsModal>
              <PsModal
                id="CampaignStatusWarningModal"
                size={400}
                type="mini"
                closeButtonClassName="!p-[10px]"
                radius="22px"
                closeButtonColor="light"
              >
                <CampaignStatusWarningModal />
              </PsModal>
              <Drawer id="FormSharing">
                <FormSharingHowItsWork />
              </Drawer>
              <ToastContainer />
            </I18nextProvider>
          </LoadingProvider>
        </PostHogProvider>
      </ApolloProvider>
    </>
  );
};

export default withRedux(App);
