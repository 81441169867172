import { FormElementsNodeType } from '@model/types';

const formElements: FormElementsNodeType[] = [
  'email-input',
  'phone-input',
  'full-name-input',
  'address-input',
  'date-input',
  'text-input',
  'long-input',
  'radio-input',
  'multi-choice',
  'dropdown-input',
  'notice-and-consent',
  'rating',
  'opinion-scale',
  'hidden-input',
];

export { formElements };
