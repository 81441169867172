export const GET_CAMPAIGNS_REQUEST = 'CAMPAIGNS/GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_SUCCESS = 'CAMPAIGNS/GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'CAMPAIGNS/GET_CAMPAIGNS_FAILURE';
export const GET_CAMPAIGNS_INVALIDATE = 'CAMPAIGNS/GET_CAMPAIGNS_INVALIDATE';

export const GET_CAMPAIGNS_COUNTS = 'CAMPAIGNS/GET_CAMPAIGNS_COUNTS';
export const GET_CAMPAIGNS_COUNTS_SUCCESS =
  'CAMPAIGNS/GET_CAMPAIGNS_COUNTS_SUCCESS';
export const GET_CAMPAIGNS_COUNTS_FAILURE =
  'CAMPAIGNS/GET_CAMPAIGNS_COUNTS_FAILURE';
export const INCREASE_CAMPAIGNS_COUNTS = 'CAMPAIGNS/INCREASE_CAMPAIGNS_COUNTS';

export const DELETE_CAMPAIGN_REQUEST = 'CAMPAIGNS/DELETE_CAMPAIGNS_REQUEST';
export const DELETE_CAMPAIGN_SUCCESS = 'CAMPAIGNS/DELETE_CAMPAIGNS_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = 'CAMPAIGNS/DELETE_CAMPAIGNS_FAILURE';

export const DUPLICATE_CAMPAIGN_REQUEST =
  'CAMPAIGNS/DUPLICATE_CAMPAIGNS_REQUEST';
export const DUPLICATE_CAMPAIGN_SUCCESS =
  'CAMPAIGNS/DUPLICATE_CAMPAIGNS_SUCCESS';
export const DUPLICATE_CAMPAIGN_FAILURE =
  'CAMPAIGNS/DUPLICATE_CAMPAIGNS_FAILURE';

export const RENAME_CAMPAIGN_REQUEST = 'CAMPAIGNS/RENAME_CAMPAIGNS_REQUEST';
export const RENAME_CAMPAIGN_SUCCESS = 'CAMPAIGNS/RENAME_CAMPAIGNS_SUCCESS';
export const RENAME_CAMPAIGN_FAILURE = 'CAMPAIGNS/RENAME_CAMPAIGNS_FAILURE';

export const CHANGE_CAMPAIGN_STATUS_REQUEST =
  'CAMPAIGNS/CHANGE_CAMPAIGNS_STATUS_REQUEST';
export const CHANGE_CAMPAIGN_STATUS_SUCCESS =
  'CAMPAIGNS/CHANGE_CAMPAIGNS_STATUS_SUCCESS';
export const CHANGE_CAMPAIGN_STATUS_FAILURE =
  'CAMPAIGNS/CHANGE_CAMPAIGN_STATUS_FAILURE';

export const GET_ALL_CAMPAIGNS_REQUEST = 'CAMPAIGNS/GET_ALL_CAMPAIGNS_REQUEST';
export const GET_ALL_CAMPAIGNS_SUCCESS = 'CAMPAIGNS/GET_ALL_CAMPAIGNS_SUCCESS';
export const GET_ALL_CAMPAIGNS_FAILURE = 'CAMPAIGNS/GET_ALL_CAMPAIGNS_FAILURE';

export const CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST =
  'CAMPAIGNS/CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST';
export const CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS =
  'CAMPAIGNS/CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS';
export const CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE =
  'CAMPAIGNS/CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE';
export const CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS =
  'CAMPAIGNS/CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS';

export const GET_CAMPAIGNS_ANALYTICS_REQUEST =
  'CAMPAIGNS/GET_CAMPAIGNS_ANALYTICS_REQUEST';
export const GET_CAMPAIGNS_ANALYTICS_SUCCESS =
  'CAMPAIGNS/GET_CAMPAIGNS_ANALYTICS_SUCCESS';
export const GET_CAMPAIGNS_ANALYTICS_FAILURE =
  'CAMPAIGNS/GET_CAMPAIGNS_ANALYTICS_FAILURE';

export const ADD_PENDING_CAMPAIGN = 'CAMPAIGNS/ADD_PENDING_CAMPAIGN';

export const RESET_PENDING_CAMPAIGN = 'CAMPAIGNS/RESET_PENDING_CAMPAIGN';

export const CLEAN_CAMPAIGNS_REQUEST = 'CAMPAIGNS/CLEAN_CAMPAIGNS_REQUEST';
