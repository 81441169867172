import { ReduxAction } from 'global';
import { takeLatest, put, call } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import {
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILURE,
  DUPLICATE_CAMPAIGN_REQUEST,
  DUPLICATE_CAMPAIGN_SUCCESS,
  DUPLICATE_CAMPAIGN_FAILURE,
  RENAME_CAMPAIGN_REQUEST,
  RENAME_CAMPAIGN_SUCCESS,
  RENAME_CAMPAIGN_FAILURE,
  CHANGE_CAMPAIGN_STATUS_REQUEST,
  CHANGE_CAMPAIGN_STATUS_SUCCESS,
  CHANGE_CAMPAIGN_STATUS_FAILURE,
  GET_ALL_CAMPAIGNS_REQUEST,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_ALL_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST,
  CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
  CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE,
  GET_CAMPAIGNS_ANALYTICS_REQUEST,
  GET_CAMPAIGNS_ANALYTICS_SUCCESS,
  GET_CAMPAIGNS_ANALYTICS_FAILURE,
  GET_CAMPAIGNS_COUNTS,
  GET_CAMPAIGNS_COUNTS_SUCCESS,
  GET_CAMPAIGNS_COUNTS_FAILURE,
} from './constants';

import fetch from '@utils/fetch';
import graphqlFetch from '@utils/graphqlFetch';
import store from '@utils/store';
import {
  CreateCampaignWithTemplateIdApiResult,
  GetCampaignsAnalyticsApiResult,
  DecodedToken,
  CreateCampaignWithTemplateIdAction,
} from './types';
import type { User } from '@connectors/auth/reducer';

export interface Campaign {
  id: number;
  name: string;
  domainId?: number;
  campaignGoalId?: number;
  jsonData?: string;
  status?: boolean;
  conversionCount?: number;
  conversionCountCurrency?: string;
  role?: 'FULL_ACCESS' | 'LIMITED_ACCESS';
  campaignGoal?: {
    id: number;
    imageUrl: string;
    name: string;
    description: string;
  };
  domains?: {
    id: number;
    userId: number;
    url: string;
    verified: boolean;
  }[];
  impressionCount?: number;
  interactionCount?: number;
  displayCount?: number;
  rate?: string;
  isHaveSubscribers?: boolean;
  publishVersion?: number;
}

const subaccountCampaignFilter = (user: User, campaigns: Campaign[]) => {
  if (user && !user?.isOwner && user?.token) {
    //If the user is a subaccount
    const decodedToken: DecodedToken = jwt_decode(user.token);
    const roleString =
      decodedToken[`${process.env.NEXT_PUBLIC_TOKEN_ROLE_KEY}`][1];

    if (roleString) {
      const roleStringType = roleString[0].trim(); //1 or 2
      const campaignIds = roleString
        .slice(2)
        .split(',')
        .map((x: string) => Number(x));
      if (roleStringType === '2') {
        //if role type is 2 (campaign access) filter campaigns
        const filteredCampaigns = campaigns.filter((x: Campaign) =>
          campaignIds.includes(x.id),
        );
        return filteredCampaigns.map((campaign) =>
          Object({ ...campaign, role: 'LIMITED_ACCESS' as Campaign['role'] }),
        );
      }
    }
  }
  //if isOwner or roletype 2 return campaigns role is full access
  return campaigns.map((campaign) =>
    Object({ ...campaign, role: 'FULL_ACCESS' as Campaign['role'] }),
  );
};

function* getCampaignsSaga({ payload }: ReduxAction) {
  try {
    const graphqlQuery = {
      operationName: 'Campaigns',
      query: `query Campaigns($filter: CampaignsFilter!) {
        campaigns(filter: $filter) {
          hasFullAccess
          total
          items {
            id
            name
            isActive
            publishVersion
            isTemplateSelected
            createdAt
            domains {
              id
              url
              verified
              cms
            }
            conversionRate
            variantInfo {
              variantId
              abTestingId
              variant {
                name
                percentage
              }
            }
          }
        }
      }`,
      variables: {
        filter: payload ? payload : {},
      },
    };

    const data: {
      data: {
        campaigns: {
          items: Campaign[];
          total: number;
          hasFullAccess: boolean;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    const user = store.get('user');
    const { items } = data.data.campaigns;
    const filteredCampaigns = subaccountCampaignFilter(user, items);

    yield put({
      type: GET_CAMPAIGNS_SUCCESS,
      payload: {
        campaigns: [...filteredCampaigns],
        total: data.data.campaigns.total,
        hasFullAccess: data.data.campaigns.hasFullAccess,
      },
    });
  } catch (err) {
    yield put({
      type: GET_CAMPAIGNS_FAILURE,
    });
  }
}

function* handleGetCampaignCounts() {
  try {
    const graphqlQuery = {
      operationName: 'getCampaignCounts',
      query: `query getCampaignCounts {
        getCampaignCounts {
          total
          active
          passive
        }
      }`,
    };
    const data: {
      data: {
        getCampaignCounts: {
          total: number;
          active: number;
          passive: number;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    yield put({
      type: GET_CAMPAIGNS_COUNTS_SUCCESS,
      payload: { ...data?.data?.getCampaignCounts },
    });
  } catch (error) {
    yield put({ type: GET_CAMPAIGNS_COUNTS_FAILURE });
  }
}

function* deleteCampaignSaga({ payload }: ReduxAction) {
  const { id, onSuccess } = payload;
  try {
    yield call(fetch, `api/Campaign/${id}`, {
      method: 'DELETE',
    });
    onSuccess && onSuccess();
    yield put({ type: DELETE_CAMPAIGN_SUCCESS, payload: id });
  } catch (error) {
    yield put({ type: DELETE_CAMPAIGN_FAILURE });
  }
}

function* duplicateCampaignSaga({ payload, onSuccess }: ReduxAction) {
  try {
    const data: Campaign = yield call(
      fetch,
      `api/Campaign/${payload}/duplicate`,
      {
        method: 'GET',
        params: payload,
      },
    );
    onSuccess && onSuccess();
    yield put({
      type: DUPLICATE_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: DUPLICATE_CAMPAIGN_FAILURE,
    });
  }
}
function* renameCampaignSaga({ payload }: ReduxAction) {
  const { id, onSuccess } = payload;
  try {
    const data: Campaign = yield call(fetch, `api/Campaign/${id}/change-name`, {
      method: 'PUT',
      data: { name: payload.name },
    });

    onSuccess && onSuccess();
    yield put({
      type: RENAME_CAMPAIGN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: RENAME_CAMPAIGN_FAILURE,
    });
  }
}

function* changeCampaignStatusSaga({ payload }: ReduxAction) {
  const { id } = payload;
  try {
    const graphqlQuery = {
      operationName: 'campaignChangeStatus',
      query: `mutation campaignChangeStatus($campaignId: Int!, $status: Boolean!) {
        campaignChangeStatus(campaignId: $campaignId, status: $status) {
          id
          name
          isActive
          publishVersion
          isTemplateSelected
          createdAt
        }
      }`,
      variables: {
        campaignId: payload.id,
        status: payload.status,
      },
    };

    yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });
    yield put({
      type: CHANGE_CAMPAIGN_STATUS_SUCCESS,
      payload: id,
    });
    payload.onSuccess && payload.onSuccess();
  } catch (err) {
    yield put({
      type: CHANGE_CAMPAIGN_STATUS_FAILURE,
      payload: id,
    });
  }
}

function* getAllCampaignsSaga() {
  try {
    const graphqlQuery = {
      operationName: 'Campaigns',
      query: `query Campaigns($filter: CampaignsFilter) {
        campaigns(filter: $filter) {
          hasFullAccess
          total
          items {
            id
            name
            isActive
            publishVersion
            isTemplateSelected
            createdAt
            domains {
              id
              url
              verified
              cms
            }
            conversionRate
          }
        }
      }`,
      variables: {
        filter: {},
      },
    };

    const data: {
      data: {
        campaigns: {
          items: Campaign[];
          total: number;
          hasFullAccess: boolean;
        };
      };
    } = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    const user = store.get('user');
    const { items } = data.data.campaigns;
    const filteredCampaigns = subaccountCampaignFilter(user, items);

    yield put({
      type: GET_ALL_CAMPAIGNS_SUCCESS,
      payload: {
        campaigns: [...filteredCampaigns],
        total: data.data.campaigns.total,
      },
    });
  } catch (err) {
    yield put({
      type: GET_ALL_CAMPAIGNS_FAILURE,
    });
  }
}

function* createCampaignWithTemplateIdSaga(
  action: CreateCampaignWithTemplateIdAction,
) {
  const { name, domains, templateId, onSuccess } = action.payload;
  try {
    const data: CreateCampaignWithTemplateIdApiResult = yield call(
      fetch,
      'api/Campaign/post-campaign-with-template',
      {
        method: 'POST',
        data: { name, domains, templateId },
      },
    );

    yield put({
      type: CREATE_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
      payload: data,
    });
    onSuccess && onSuccess(data.id);
  } catch (err) {
    yield put({
      type: CREATE_CAMPAIGN_WITH_TEMPLATE_FAILURE,
    });
  }
}

function* getCampaignsAnalyticsSaga(action: ReduxAction) {
  try {
    // const res: GetCampaignsAnalyticsApiResult = yield call(
    //   fetch,
    //   'api/Campaign/analytics',
    //   {
    //     method: 'POST',
    //     data: action.payload,
    //   },
    // );

    const graphqlQuery = {
      operationName: 'campaignListAnalytics',
      query: `query campaignListAnalytics($campaignIds: [Int!]!) {
        campaignDailyAnalytics(campaignIds: $campaignIds) {
          campaignId
          display
          interaction
          lead
          abTestingId
        }
      }`,
      variables: {
        campaignIds: action?.payload?.campaignIds,
      },
    };

    const res: GetCampaignsAnalyticsApiResult = yield call(graphqlFetch, ``, {
      method: 'POST',
      data: graphqlQuery,
    });

    yield put({
      type: GET_CAMPAIGNS_ANALYTICS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put({
      type: GET_CAMPAIGNS_ANALYTICS_FAILURE,
    });
  }
}

export default function* sagas() {
  yield takeLatest(GET_CAMPAIGNS_REQUEST, getCampaignsSaga);
  yield takeLatest(GET_CAMPAIGNS_COUNTS, handleGetCampaignCounts);
  yield takeLatest(DELETE_CAMPAIGN_REQUEST, deleteCampaignSaga);
  yield takeLatest(DUPLICATE_CAMPAIGN_REQUEST, duplicateCampaignSaga);
  yield takeLatest(RENAME_CAMPAIGN_REQUEST, renameCampaignSaga);
  yield takeLatest(CHANGE_CAMPAIGN_STATUS_REQUEST, changeCampaignStatusSaga);
  yield takeLatest(GET_ALL_CAMPAIGNS_REQUEST, getAllCampaignsSaga);
  yield takeLatest(
    CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST,
    createCampaignWithTemplateIdSaga,
  );
  yield takeLatest(GET_CAMPAIGNS_ANALYTICS_REQUEST, getCampaignsAnalyticsSaga);
}
