import { GET_FEATURES_REQUEST, GET_PRODUCTS_REQUEST } from './constants';

export function getProducts(payload?: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return {
    type: GET_PRODUCTS_REQUEST,
    payload,
  };
}
export function getFeatures() {
  return {
    type: GET_FEATURES_REQUEST,
  };
}
