import React, { FC, useEffect, useState } from 'react';
import styles from './UnlockFeaturesModal.module.scss';
// import Switch from '@elements/Switch';
import cn from 'classnames';
import { RootState } from '@connectors/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { PlanItem, ProductsStateType } from '@connectors/products/types';
import { getProducts } from '@connectors/products/actions';
import PSButton from '@elements/PSButton';
import { useRouter } from 'next/navigation';
import { makeSelectModalStatus } from '@connectors/modal/selectors';
import ReduxStatus from '@enums/ReduxStatus';
import { useAuth } from '@utils/useAuth';
import {
  useCreatePortalLinkLazyQuery,
  useCreateSessionIdLazyQuery,
  useCreateShopifySubscriptionMutation,
} from '@generated/graphql';
import PopupsmartIcon from '@assets/icons/popupsmart-rounded.svg';
import UnlockIcon from '@assets/icons/unlock-icon.svg';
import { hideModal, showModal } from '@connectors/modal/actions';
import { UnlockFeaturesModalProps } from './types';
import UnlockFeaturesModalTypes from '@enums/UnlockFeaturesModalEnums';
import {
  CREATE_PORTAL_FAILURE,
  CREATE_PORTAL_SUCCESS,
} from '@connectors/stripe/constants';
import Switch from '@elements/Switch';
import { PlanType } from '@features/account/billing/types';
import { useTranslation } from 'react-i18next';

const UnlockFeaturesModal: FC<UnlockFeaturesModalProps> = ({ type }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const modalStatus = useSelector(makeSelectModalStatus);
  const stripe = useSelector((state: RootState) => state.stripe);
  const { auth } = useAuth();
  const [createShopifySubscription] = useCreateShopifySubscriptionMutation({
    fetchPolicy: 'no-cache',
  });
  const [createPortalLink] = useCreatePortalLinkLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [createSessionId] = useCreateSessionIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const products = useSelector(
    (state: RootState) => state.products.products as ProductsStateType,
  );

  const [paymentLoading, setPaymentLoading] = useState({
    status: false,
    redirect: false,
  });

  const [plan, setPlan] = useState<{
    type: PlanType;
    selected: PlanItem | null;
  }>({
    type: 'Yearly',
    selected: null,
  });

  useEffect(() => {
    if (products.status === ReduxStatus.initialized) {
      dispatch(getProducts());
    }
  }, []);

  function getFilteredPlansByVersionThree(planType: PlanType = 'Yearly') {
    return products.data[planType].filter((plan: any) => plan.version === 3);
  }
  useEffect(() => {
    // UPDATE CODE HERE IF PLAN VERSİON CHANGES THE PROJECT
    const planTypeFromAuth = auth?.accountPlan?.type as PlanType;
    const currentPlanIndex =
      planTypeFromAuth === 'Yearly'
        ? getFilteredPlansByVersionThree(planTypeFromAuth).findIndex(
            (plan) => plan.name === auth.accountPlan.name,
          )
        : products.data[planTypeFromAuth || 'Yearly'].findIndex(
            (plan) => plan.planId === auth.accountPlan.id,
          );
    // UPDATE CODE HERE IF PLAN VERSİON CHANGES THE PROJECT
    const planPeriod =
      planTypeFromAuth === 'Yearly'
        ? getFilteredPlansByVersionThree(planTypeFromAuth)
        : products.data[planTypeFromAuth || 'Yearly'];

    setPlan({
      type: planTypeFromAuth || 'Yearly',
      selected: planPeriod[currentPlanIndex + 1],
    });
  }, [auth]);

  useEffect(() => {
    const { url } = stripe;
    if (stripe.status !== 'loading')
      setPaymentLoading({ status: false, redirect: true });
    if (url) {
      router.push(url);
    }
  }, [stripe]);

  useEffect(() => {
    if (modalStatus === 'add') {
      setPaymentLoading({ status: false, redirect: false });
    }
  }, [modalStatus]);

  const handleSelectPlan = (selected: PlanItem) => {
    setPlan((state) => ({ ...state, selected }));
  };

  const handleTogglePlanType = () => {
    const type = plan.type === 'Monthly' ? 'Yearly' : 'Monthly';
    // UPDATE CODE HERE IF PLAN VERSİON CHANGES THE PROJECT
    const currentPlan =
      type === 'Yearly'
        ? getFilteredPlansByVersionThree(type).find(
            (productPlan) => productPlan.name === plan.selected?.name,
          )
        : products.data[type].find(
            (productPlan) => productPlan.name === plan.selected?.name,
          );

    setPlan({
      type,
      selected: currentPlan!,
    });
  };

  const numberFormatter = (pageViewSize: number) => {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(pageViewSize);
  };
  const handleOnClickPayButton = () => {
    if (auth.accountPlan.name === 'Expert') {
      window.open('https://popupsmart.com/enterprise-sales/', '_blank');
      dispatch(hideModal('UnlockFeaturesModal'));
      return;
    }
    setPaymentLoading({ status: true, redirect: false });
    if (auth.isShopifyUser) {
      createShopifySubscription({
        variables: { planId: plan.selected?.planId || 0 },
        onCompleted: (res) => {
          window.location.href = res.createShopifySubscription;
        },
        onError: () => {
          setPaymentLoading({ status: false, redirect: false });
        },
      });
    } else {
      if (auth.accountPlan.price === 0 && plan.selected) {
        createSessionId({
          variables: {
            priceId: plan.selected.priceId,
          },
          onCompleted: (data) => {
            if (data.createSessionId.url) {
              dispatch({
                type: CREATE_PORTAL_SUCCESS,
                payload: data.createSessionId,
              });
            } else {
              dispatch({
                type: CREATE_PORTAL_FAILURE,
                payload: "Couldn't redirect to stripe",
              });
            }
          },
          onError: () => {
            dispatch({
              type: CREATE_PORTAL_FAILURE,
              payload: "Couldn't redirect to stripe",
            });
          },
        });
      }
      createPortalLink({
        onCompleted: (data) => {
          if (data.createPortalLink.url) {
            dispatch({
              type: CREATE_PORTAL_SUCCESS,
              payload: data.createPortalLink,
            });
          } else {
            dispatch({
              type: CREATE_PORTAL_FAILURE,
              payload: "Couldn't redirect to stripe",
            });
          }
        },
        onError: () => {
          dispatch({
            type: CREATE_PORTAL_FAILURE,
            payload: "Couldn't redirect to stripe",
          });
        },
      });
    }
  };

  const renderPaymentButtonText = () => {
    let buttonText = '';
    const planTypeText = plan.type === 'Yearly' ? 'Annually' : plan.type;

    if (!plan.selected) {
      buttonText = t('sections:unlock-features-modal:buttonTextOne');
    }

    if (auth.accountPlan.name === 'Expert') {
      return t('sections:unlock-features-modal:buttonTextTwo');
    }

    if (plan.selected) {
      if (plan.type === 'Yearly') {
        buttonText = `${t('common:pay')} $${
          plan.selected.saveText?.new || plan.selected.price * 12
        } ${planTypeText}`;
      } else {
        buttonText = `${t('common:pay')} $${
          plan.selected.price
        } ${planTypeText}`;
      }
    }

    if (paymentLoading.redirect) {
      buttonText = t('sections:unlock-features-modal:buttonTextThree');
    }

    return buttonText;
  };
  // UPDATE CODE HERE IF PLAN VERSİON CHANGES THE PROJECT
  const getAvailablePlans = (currentPlan?: string) => {
    const plans = products.data[plan.type];
    if (!currentPlan) {
      // If the user is on the "Free Plan," show all plans
      return plans.filter((item) =>
        plan.type === 'Yearly' ? item.version === 3 : item.version === 2,
      );
    }
    // Find the index of the user's current plan in the plans array
    const currentPlanIndex = plans.findIndex(
      (plan) => plan.name === currentPlan,
    );
    if (currentPlanIndex === -1) {
      // // If the user's current plan is not found in the plans array, show all plans as a fallback
      return plans.filter((item) =>
        plan.type === 'Yearly' ? item.version === 3 : item.version === 2,
      );
    }
    // Return the plans that are greater than the user's current plan
    return (
      plans
        .filter((item) =>
          plan.type === 'Yearly' ? item.version === 3 : item.version === 2,
        )
        // modded since new plans added on old ones
        .slice((currentPlanIndex % 3) + 1)
    );
  };

  const getDescription = () => {
    switch (type) {
      case UnlockFeaturesModalTypes.LIMIT:
        return t('sections:unlock-features-modal:limitDescription');
      case UnlockFeaturesModalTypes.PUBLISH:
        return t('sections:unlock-features-modal:limitDescription');
      case UnlockFeaturesModalTypes.WEBSITE:
        return t('sections:unlock-features-modal:websiteDescription');
      default:
        return t('sections:unlock-features-modal:description');
    }
  };

  const addDecimalsToYearlyPrice = (price: number) => {
    if (plan.type === 'Yearly' && !price.toString().includes('.')) {
      return `${price}.00`;
    }
    return price;
  };

  return (
    <div className={styles.unlockFeaturesModal}>
      <div className={styles.rightBlock}>
        <div className={styles.header}>
          <div className="flex">
            <div className={styles.icon}>
              <PopupsmartIcon width="32" height="32" viewBox="0 0 48 48" />
            </div>
            <div className={cn(styles.icon, 'ml-[-10px] bg-white')}>
              <UnlockIcon />
            </div>
          </div>
        </div>
        <div className={styles.title}>
          {t('sections:unlock-features-modal:title')}
        </div>
        <div className={styles.description}>{getDescription()}</div>
        <div className={styles.note}>
          {t('sections:unlock-features-modal:note', {
            plan: auth?.accountPlan?.description,
          })}{' '}
          <span
            className="font-medium cursor-pointer"
            onClick={() => {
              dispatch(hideModal('UnlockFeaturesModal'));
              dispatch(
                showModal({
                  id: 'CompareDetailsModal',
                  data: {
                    isOpenedByUnlockModal: true,
                    type: type as string,
                  },
                }),
              );
            }}
          >
            {t('sections:unlock-features-modal:compare')}
          </span>
        </div>

        {auth?.accountPlan?.name! !== 'Expert' && (
          <div className={styles.switchContainer}>
            <span>{t('common:annually')}</span>
            <Switch
              elementSize="xs"
              checked={plan.type === 'Monthly'}
              onChange={handleTogglePlanType}
            />
            <span>{t('common:monthly')}</span>
          </div>
        )}
        <div className={styles.plansContainer}>
          {getAvailablePlans(auth?.accountPlan?.name!).map(
            ({ name, impressionCount, price }, index, productsArray) => (
              <div
                onClick={() => handleSelectPlan(productsArray[index])}
                key={index}
                className={cn(styles.plan, {
                  [styles.selected]: name === plan.selected?.name,
                })}
              >
                <div className={styles.left}>
                  <div className={styles.selectPlanRadioButton}>
                    <span className={styles.dot} />
                  </div>
                  <div>
                    <div className={styles.planTitle}>{name}</div>
                    <div className={styles.planSubtitle}>
                      <span className="font-medium">
                        {numberFormatter(impressionCount)}{' '}
                        {t('common:pageviews')}
                      </span>
                      <span> / {t('common:monthly').toLowerCase()}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.planPriceContainer}>
                    <span className={styles.price}>
                      {addDecimalsToYearlyPrice(price)}$
                    </span>
                    <span className={styles.type}>
                      / {t('common:monthlyShort').toLowerCase()}
                    </span>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>

        <div className={styles.buttons}>
          <PSButton
            type="button"
            size="sm"
            variant="second"
            onClick={() => {
              dispatch(hideModal('UnlockFeaturesModal'));
            }}
            textClassName="text-xs text-[#777]"
          >
            {t('common:later')}
          </PSButton>
          <PSButton
            type="button"
            size="sm"
            loading={paymentLoading.status}
            onClick={handleOnClickPayButton}
            disabled={
              (!plan.selected && auth.accountPlan.name !== 'Expert') ||
              paymentLoading.status
            }
            className={styles.acceptButton}
          >
            {renderPaymentButtonText()}
          </PSButton>
        </div>
      </div>
    </div>
  );
};

export default UnlockFeaturesModal;
